/* global ajax_object */

/** @type {WeakMap<HTMLElement,AbortController>} */
export const CONTROLLER_CACHE = new WeakMap();

/**
 * Make AJAX request to WordPress.
 *
 * @param {RequestInit}     [options]    Fetch options.
 * @param {AbortController} [controller] Controller for current request.
 */
export const doRequest = async ( options = {}, controller = null ) => {
	const response = await fetch( ajax_object.ajax_url, {
		// eslint-disable-line camelcase
		method: 'POST',
		signal: controller?.signal,
		...options,
	} );

	// Make sure we have a 2xx status code.
	if ( ! response.ok ) {
		throw new Error( `${ response.statusText } (Response is not ok!)` );
	}

	// Get JSON response.
	const { success, data } = await response.json();

	// Make sure the response is successful.
	if ( ! success ) {
		throw new Error( data );
	}

	// Return data.
	return data;
};

/**
 *  Append query parameters to URL without reloading.
 *
 * @param {Object<string, string>} params
 */
export const updateURLParams = ( params ) => {
	// Create new URL without parameters.
	let path_name = window.location.pathname;

	// replace /page/2 if exist
	if ( path_name.includes( '/page/' ) ) {
		path_name = path_name.replace( /page\/[^/]+\//, '' );
	}
	const url = new URL( path_name, window.location.origin );
	for ( const [ key, value ] of Object.entries( params ) ) {
		// Skip if element has an empty value.
		if ( value === '' ) {
			continue;
		}

		// Append query parameter.
		url.searchParams.append( key, value );
	}

	// Update URL.
	window.history.pushState( {}, '', url );
};
