/* global i18n_object */
/**
 * Import internal dependencies.
 */
import { doRequest } from '../helpers/request';
import { LAZYLOAD_UPDATE_EVENT } from './lazyload';
import { SetCompares } from './comparison';

const favoritecounter = document.querySelector( '.button-floating-favorite.show-for-large' );
const mobileCounter = document.querySelector( '.button-favorite' );
const navbar = document.querySelector( '.main-nav' );
/**
 * adds car to localstorage
 *
 * @param {string} id
 */
export function addCarToStorage( id ) {
	const storedCars = getStoredCars();

	if ( ! storedCars.includes( id ) ) {
		storedCars.push( id );
		localStorage.setItem( 'cars', JSON.stringify( storedCars ) );
	}

	return storedCars;
}

/**
 * Removes favorized cars
 *
 * @param {string} id
 */
export function removeCarFromStorage( id ) {
	const storedCars = getStoredCars();

	const result = storedCars.filter( ( carId ) => carId !== id );
	localStorage.setItem( 'cars', JSON.stringify( result ) );

	amountOfFavorites();
	return result;
}
/**
 * Stores all for cars being favorized
 *
 */
export function getStoredCars() {
	const storedCars = localStorage.getItem( 'cars' );

	if ( ! storedCars ) {
		return [];
	}
	return JSON.parse( storedCars );
}

/**
 * set Favorites from localstorage
 *
 */
export function Setfavorites() {
	const storedCars = getStoredCars();
	for ( const storedCar of storedCars ) {
		const element = document.querySelector( `[data-car="${ storedCar }"]` );
		if ( element?.classList ) {
			element.classList.add( 'added' );
		}
	}

	amountOfFavorites();
}

/**
 * adds car to localstorage
 *
 */
export function CheckCarPage() {
	const storedCars = getStoredCars();

	for ( const storedCar of storedCars ) {
		const element = document.querySelector( `.button[data-car="${ storedCar }"]` );
		if ( element?.classList ) {
			element.classList.add( 'added' );
			element.innerHTML = i18n_object.remove_from_favorites + '<i class="icon icon-heart-small" aria-hidden="true"></i>'; // eslint-disable-line camelcase
		}
	}
	amountOfFavorites();
}

/**
 * counts Favorites
 *
 */
export function FavoriteCounter( ) {
	let counterFavorite = amountOfFavorites();
	if ( JSON.parse( localStorage.getItem( 'cars' ) )?.length ) {
		const countAll = JSON.parse( localStorage.getItem( 'cars' ) ).length;

		counterFavorite = localStorage.setItem( 'favorites', JSON.stringify( countAll ) );
		favoritecounter.classList.add( 'is-active' );
		if ( navbar ) {
			mobileCounter.classList.add( 'is-active' );
		}
		const counter = document.querySelectorAll( '.nbr-count.favorite' );
		for ( const count of counter ) {
			count.textContent = countAll;
		}
		return counterFavorite;
	}
}

/**
 * Stores amount of favorized
 *
 */
export function amountOfFavorites() {
	const storedAmount = getStoredCars().length;
	const counter = document.querySelectorAll( '.nbr-count.favorite' );
	const favorites = 0;

	if ( storedAmount !== null ) {
		const amountfavorites = storedAmount ?? favorites;

		if ( amountfavorites <= 0 ) {
			favoritecounter.classList.remove( 'is-active' );
			if ( navbar ) {
				mobileCounter.classList.remove( 'is-active' );
			}
			for ( const count of counter ) {
				count.textContent = amountfavorites;
				count.classList.add( 'hide' );
			}

			return;
		}

		for ( const count of counter ) {
			count.textContent = amountfavorites;
			count.classList.remove( 'hide' );
		}

		favoritecounter.classList.add( 'is-active' );
		if ( navbar ) {
			mobileCounter.classList.add( 'is-active' );
		}
		return JSON.parse( amountfavorites );
	} else if ( ! document.querySelectorAll( '.added' ).length ) {
		const amountfavorites = favorites;

		for ( const count of counter ) {
			count.textContent = amountfavorites;
			mobileCounter.classList.remove( 'is-active' );
			favoritecounter.classList.remove( 'is-active' );
			count.classList.add( 'hide' );
		}
	}
}

/**
 * Eventlistener for favoritizing car on productoverview & favorite page
 */
( () => {
	let productWrapper = document.querySelector( '.woocommerce-products-wrapper' ) ? document.querySelector( '.woocommerce-products-wrapper' ) : document.querySelector( '.swiper-cars' );
	productWrapper = productWrapper ? productWrapper : document.querySelector( '.compare-wrapper' );
	productWrapper = productWrapper ? productWrapper : document.querySelector( '.section-form' );
	if ( ! productWrapper ) {
		return;
	}
	Setfavorites();

	productWrapper.addEventListener( 'click', ( event ) => {
		const favoriteBtn = event.target.closest( '.card-favorite.favorite' );
		const card = event.target.closest( '.card-product-car' );
		if ( ! favoriteBtn ) {
			return;
		}

		const data = favoriteBtn.getAttribute( 'data-car' );

		if ( typeof ( Storage ) !== 'undefined' ) {
			event.preventDefault();
			favoriteBtn.classList.toggle( 'added' );
			const countAll = amountOfFavorites;

			if ( ! countAll ) {
				window.localStorage.clear();
				amountOfFavorites( );
			} else {
				addCarToStorage( data );
				FavoriteCounter();
			}

			if ( ! favoriteBtn.classList.contains( 'added' ) ) {
				removeCarFromStorage( data );
				if ( document.querySelector( '.section-favorites' ) ) {
					card.classList.add( 'card-fadeout' );
					setTimeout( () => {
						card.remove();
					}, 500 );
					if ( ! amountOfFavorites() ) {
						setTimeout( () => {
							card.closest( '.favorite-grid' ).innerHTML = '<div class="cell"><p class="no-results">Je hebt nog geen favorieten! Bekijk ons aanbod.</p></div>';
						}, 45 );
					}
				}
			}
		}
	}, false );
} )();

/**
 * Eventlistener for favoritizing car on productpage
 */
( () => {
	const productWrapper = document.querySelector( '.section-hero-product' );
	if ( ! productWrapper ) {
		return;
	}
	CheckCarPage();

	productWrapper.addEventListener( 'click', ( event ) => {
		const favoriteBtnProduct = event.target.closest( '#js-product-favorite' );
		if ( favoriteBtnProduct ) {
			const data = favoriteBtnProduct.getAttribute( 'data-car' );
			const element = document.querySelector( `.button[data-car="${ data }"]` );

			if ( ! favoriteBtnProduct ) {
				return;
			}
			if ( typeof ( Storage ) !== 'undefined' ) {
				favoriteBtnProduct.classList.toggle( 'added' );
				const countAll = amountOfFavorites;

				if ( ! countAll ) {
					window.localStorage.clear();
					amountOfFavorites( );
				} else if ( element ) {
					element.innerHTML = i18n_object.remove_from_favorites + '<i class="icon icon-heart-small" aria-hidden="true"></i>'; // eslint-disable-line camelcase
					addCarToStorage( data );
					FavoriteCounter();
				}

				if ( ! favoriteBtnProduct.classList.contains( 'added' ) ) {
					if ( element ) {
						element.innerHTML = i18n_object.add_to_favorites + '<i class="icon icon-heart-small" aria-hidden="true"></i>'; // eslint-disable-line camelcase
						removeCarFromStorage( data );
					}
				}
			}
		}
	}, false );
} )();

/**
 * Get favoritized cars on favorite page
 */
( async () => {
	const favoriteGrid = document.querySelector( '.favorite-grid' );

	if ( ! favoriteGrid ) {
		return;
	}

	const { nonce } = favoriteGrid.dataset;

	const body = new FormData();
	body.set( 'action', 'request_favorites' );
	body.set( 'mk_security', nonce );

	//Gets posts from localstorage.
	const storedCars = getStoredCars();
	for ( const storedCar of storedCars ) {
		body.append( 'post_id[]', storedCar );
	}

	try {
		const data = await doRequest( { body } );
		favoriteGrid.innerHTML = data;
	} catch ( error ) {
		// Silence error.
		console.log( error ); // eslint-disable-line no-console
	} finally {
		// Hides loader & set favorites with comparison
		Setfavorites();
		document.querySelector( '.loader.large' ).classList.add( 'hide' );
		SetCompares();

		// Fix images.
		favoriteGrid.dispatchEvent( LAZYLOAD_UPDATE_EVENT );
	}
} )();
