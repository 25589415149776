/**
 * Regex explanation: (dutch zipcode)
 *
 * - `^`                    Start of string
 * - `[1-9]`                A number from 1 to 9
 * - `[0-9]{3}`             Three numbers from 0 to 9
 * - ` ?`                   Optional space (notice the space in front of the question mark)
 * - `(?!SA|SD|SS)[A-Z]{2}` Two letters from A to Z not starting with SA, SD or SS
 * - `$`                    End of string
 */
export const ZIP_CODE_REGEX = /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/;

/**
 * Validate (dutch) zipcode.
 *
 * @param {string} zipcode Zipcode to check.
 *
 * @return {boolean} Whether the zipcode is valid.
 */
export const validateZip = ( zipcode ) => {
	const value = zipcode.toUpperCase().trim();
	return ZIP_CODE_REGEX.test( value );
};

/**
 * Regex explanation: (international email address)
 *
 * @see {@link http://emailregex.com/}
 */
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validate (international) email address.
 *
 * @param {string} email Email address to check.
 *
 * @return {boolean} Whether the email address is valid.
 */
export const validateEmail = ( email ) => {
	const value = email.trim();
	return EMAIL_REGEX.test( value );
};

/**
 * Regex explanation: (dutch mobile numbers)
 *
 * - `^`                                       Start of string
 * - `(?:\+31|\+31\(0\)|\(\+31\)\(0\)|0|0031)` Check if string starts with one of the following prefixes:
 *   - `\+31`          +31
 *   - `\+31\(0\)`     +31(0)
 *   - `\(\+31\)\(0\)` (+31)(0)
 *   - `0`             0
 *   - `0031`          0031
 * - `[1-9]`                                   A number from 1 to 9
 * - `[0-9]{8}`                                Eight numbers from 0 to 9
 * - `$`                                       End of string
 */
export const MOBILE_REGEX = /^(?:\+31|\+31\(0\)|\(\+31\)\(0\)|0|0031)[1-9][0-9]{8}$/;

/**
 * Validate (dutch) mobile numbers.
 *
 * @param {string} phone Phone number to check.
 *
 * @return {boolean} Whether the phone number is valid.
 */
export const validateMobile = ( phone ) => {
	const value = phone.trim();
	// return MOBILE_REGEX.test( value );
	return value.match( /^[^a-zA-Z]+$/ );
};

/**
 * Validate field for empty value.
 *
 * @param {string} text Value to check.
 *
 * @return {boolean} Whether the value is not empty.
 */
export const validateEmpty = ( text ) => {
	const value = text.trim();
	return value !== '';
};
