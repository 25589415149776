/* global $ */

/**
 * Import external dependencies.
 */
import LazyLoad from 'vanilla-lazyload';

export const LAZYLOAD_UPDATE_EVENT = new Event( 'mdk.lazyload.update', { bubbles: true } );

/**
 * Lazy load images.
 */
const lazyLoad = new LazyLoad( {
	elements_selector: '.lazy',
	load_delay: 300,
	callback_enter( el ) {
		lazyInterchange( el );
	},
} );

/**
 * Move `data-lazy` attribute content to `data-interchange` attribute.
 *
 * @param {HTMLImageElement} image
 */
const lazyInterchange = ( image ) => {
	// Exit if data-lazy doesn't exist.
	if ( ! image.hasAttribute( 'data-lazy' ) ) {
		return;
	}

	// Replace data-* attribute.
	image.setAttribute( 'data-interchange', image.getAttribute( 'data-lazy' ) );
	image.removeAttribute( 'data-lazy' );

	// Initialize foundation on our images.
	$( image ).foundation();
};

/**
 * Update images when custom event fires.
 */
document.addEventListener( 'mdk.lazyload.update', () => {
	lazyLoad.update();
} );

/**
 * Load images when modal opens.
 */
$( '#mediaModal, #mediagridModal' ).on( 'open.zf.reveal', function() {
	const lazyModalImages = document.querySelectorAll( '.lazy-reveal' );

	for ( const image of lazyModalImages ) {
		lazyInterchange( image );
		LazyLoad.load( image );
	}
} );

