/* global $ */

/**
 * Import local dependencies.
 */
import '../_vendor/select2/select2.js';
import '../_vendor/select2/select2-lang.js';
import '../_vendor/select2/select2-search.js';

const SELECT2_SHARED_PROPS = {
	language: 'nl',
	theme: 'foundation',
	dropdownPosition: 'below',
	placeholder() {
		$( this ).data( 'placeholder' );
	},
};

/**
 * Select2 with search box.
 */
$( '.js-select, .country_select' ).select2( {
	...SELECT2_SHARED_PROPS,
} );

/**
 * Select2 without search box.
 */
$( '.js-select-hide-search' ).select2( {
	...SELECT2_SHARED_PROPS,
	minimumResultsForSearch: Infinity,
} );

/**
 * Select2 multi select.
 */
$( '.js-multi-select' ).select2( {
	...SELECT2_SHARED_PROPS,
} );
