/* global nfRadio */

/**
 * Ninjaform styling classes
 */
import FloatLabels from 'float-labels.js';

// Waits till ninja forms is completely loaded
$( document ).on( 'nfFormReady', function( e, layoutView ) {
	// Floatlabels
	// -------------
	const floatlabels = new FloatLabels( 'form .nf-form-content, form.fl-form, .fl-form', {
		customEvent: null,
		customLabel: null,
		customPlaceholder: null,
		exclude: '.no-label',
		inputRegex: /email|number|password|search|tel|text|url/,
		prefix: 'fl-',
		prioritize: 'placeholder',
		requiredClass: 'required',
		style: 2,
		transform: 'input, textarea',
	} );
	floatlabels.rebuild();

	nfRadio.channel( 'nfMP' ).on( 'change:part', function() {
		floatlabels.rebuild();
	} );

	const els = document.querySelectorAll( '.nf-field-element input, .nf-field-element textarea' );

	for ( const el of els ) {
		el.addEventListener( 'change', () => {
			requestAnimationFrame( () => {
				floatlabels.rebuild();
			}, 500 );
		}, false );
	}
} );

