/**
 * Format price for based on Nl-nl locale.
 *
 * @param {number} price
 *
 * @return {string} Formatted price.
 */
const getFormattedPrice = ( price ) => {
	if ( isNaN( price ) ) {
		price = 0;
	}

	const doShowDecimals = price % 1 !== 0;

	const formatOptions = {
		style: 'currency',
		currency: 'EUR',
		maximumFractionDigits: doShowDecimals ? 2 : 0,
		signDisplay: 'never',
	};

	return new Intl.NumberFormat( 'nl-NL', formatOptions ).format( price );
};

( () => {
	const costs = {
		label: '',
		price: 0,
	};

	const costsHandler = {
		// Don't need to listen to get calls.
		get() {
			return Reflect.get( ...arguments );
		},
		set( obj, prop, value ) {
			// Exit if we don't change the price.
			if ( prop !== 'price' ) {
				return Reflect.set( ...arguments );
			}

			// Get elements.
			const serviceLabel = document.querySelector( '#service-label' );
			const servicePrice = document.querySelector( '#service-price' );
			const priceTotal = document.querySelector( '#price-total' );
			const bottomBarPrice = document.querySelector( '.product-bar .updateCartTotal' );

			// Exit if elements don't exist.
			if ( ! serviceLabel || ! servicePrice ) {
				return Reflect.set( ...arguments );
			}

			// Update HTML contents
			serviceLabel.textContent = obj.label;
			servicePrice.textContent = getFormattedPrice( value );

			// Set total price.
			const total = parseFloat( priceTotal.dataset?.price || 0 ) + parseFloat( value );
			priceTotal.textContent = getFormattedPrice( total );
			bottomBarPrice.textContent = getFormattedPrice( total );

			// Set price excluding tax.
			const taxRate = 0.21; // 21%.
			const pricePreTax = total - ( ( total / ( 1 + taxRate ) * taxRate ) );

			// Update object.
			return Reflect.set( ...arguments );
		},
	};

	const costsProxy = new Proxy( costs, costsHandler );

	const services = document.querySelector( '.woocommerce-services-fields' );

	// Exit if no services are found.
	if ( ! services ) {
		return;
	}

	/**
	 * Update costs proxy when changing selection.
	 *
	 * @param {Event} event
	 */
	const onServiceChange = ( { target } ) => {
		costsProxy.label = target.parentElement.querySelector( '.radio-title' )?.textContent;
		costsProxy.price = target.dataset.price;
	};

	// Set default value.
	const selected = services.querySelector( 'input:checked' );
	if ( selected ) {
		onServiceChange( { target: selected } );
	}

	// Add change event to services.
	services.addEventListener( 'change', onServiceChange, false );
} )();
