document.addEventListener( 'DOMContentLoaded', function() {
	const filterForm = document.getElementById( 'occasionsFilter' );

	if ( filterForm ) {
		const params = {};
		const occasionsBrands = document.getElementById( 'occasionsFilterBrands' );
		const occasionsModels = document.getElementById( 'occasionsFilterModels' );
		const occasionsPrice = document.getElementById( 'occasionsFilterPrice' );
		const occasionButtonTitle = document.querySelector( '#show-results>.button-text' );
		const occasionsTypes = [ {
			slug: 'top',
			title: 'Top merken',
		}, {
			slug: 'default',
			title: 'Andere merken',
		} ];

		const occasionsDataArray = occasionsData;
		const select2Data = [];

		// get all types like top brands or normal brands
		for ( let i = 0; i < occasionsTypes.length; i++ ) {
			const type = occasionsTypes[ i ];
			const typeSlug = type.slug;
			const typeTitle = type.title;
			const occasionsDataByType = occasionsDataArray[ typeSlug ];
			const select2Children = new Array();

			// check if type is excist
			if ( occasionsDataByType ) {
				// foreach primary occasion
				for ( const singleOccasion in occasionsDataByType ) {
					// setup select 2 data object
					const singleOccationObject = {
						id: `${ singleOccasion }`,
						text: `${ occasionsDataByType[ singleOccasion ].title }`,
						title: `${ singleOccasion }`,
						type: `${ typeSlug }`,
					};
					select2Children.push( singleOccationObject );
				}
			}

			// add to select 2 data object
			if ( _.size( select2Children ) > 0 ) {
				select2Data.push( {
					text: typeTitle,
					children: Object.values( select2Children ),
				} );
			}
		}

		// set select 2 data with brands
		$( occasionsBrands ).select2( {
			data: select2Data,
		} );

		// onchange primary brand to add the right models
		$( occasionsBrands ).on( 'select2:select', function( e ) {
			const currentCat = e.params.data.title;
			const currentType = e.params.data.type;
			const children = occasionsDataArray[ currentType ][ currentCat ].children;
			$( occasionsModels ).html( '<option></option>' ).select2( {
				data: Object.values( children ),
			} ).prop( 'disabled', false );

			$( occasionsPrice ).val( null ).trigger( 'change' ); // reset prices
			occasionButtonTitle.innerHTML = `Bekijk alle resultaten`;
			params.product_cat = currentCat;
		} );

		// onchange models to add the right models
		$( occasionsModels ).on( 'select2:select', function() {
			$( occasionsPrice ).val( null ).trigger( 'change' ); // reset prices
			occasionButtonTitle.innerHTML = `Bekijk alle resultaten`;
		} );

		// onchange prices to add the right models
		$( occasionsPrice ).on( 'select2:select', function( e ) {
			const selectedBrand = Object.values( $( occasionsBrands ).select2( 'data' ) )[ 0 ];
			const selectedModel = Object.values( $( occasionsModels ).select2( 'data' ) )[ 0 ];
			const priceTill = e.params.data.id;
			params[ 'max-price' ] = priceTill;
			let pricesCount = '';

			if ( selectedBrand.id !== '' && selectedModel.id !== '' ) {
				const brand = selectedBrand.title;
				const type = selectedBrand.type;
				const slug = selectedModel.id;

				const prices = occasionsDataArray[ type ][ brand ].children[ slug ].prices;
				if ( prices.length > 0 ) {
					pricesCount = 0;

					for ( const price of prices ) {
						if ( parseInt( price ) <= parseInt( priceTill ) ) {
							pricesCount++;
						}
					}
				}
			} else if ( selectedBrand.id != '' ) {
				const brand = selectedBrand.title;
				const type = selectedBrand.type;
				const prices = occasionsDataArray[ type ][ brand ].prices;

				if ( prices.length > 0 ) {
					pricesCount = 0;

					for ( const price of prices ) {
						if ( parseInt( price ) <= parseInt( priceTill ) ) {
							pricesCount++;
						}
					}
				}
			}

			// set button text
			if ( pricesCount !== '' ) {
				occasionButtonTitle.innerHTML = `Bekijk ${ pricesCount } resulta` + ( pricesCount === 1 ? 'at' : 'ten' );
			} else {
				occasionButtonTitle.innerHTML = `Bekijk alle resultaten`;
			}
		} );

		// // submit form hook
		// filterForm.on( 'submit', function( e ) {
		// 	if ( ! $.isEmptyObject( params ) ) {
		// 		let url = [ location.protocol, '//', location.host, location.pathname ].join( '' );
		// 		url = url.replace( /\/page\/\d+\//g, '' );
		// 		window.location.href = url + '?' + $.param( params ).replace( /%2C/g, ',' );
		// 	} else {
		// 		let url = [ location.protocol, '//', location.host, location.pathname ].join( '' );
		// 		url = url.replace( /\/page\/\d+\//g, '' );
		// 		window.location.href = url;
		// 	}

		// 	return false;
		// } );
	}
}, false );
