/**
 * Create Swiper with lazy loading.
 *
 * @param {string}        selector
 * @param {SwiperOptions} options
 *
 * @return {Swiper|undefined} Swiper instance.
 */
const createSwiper = async ( selector, options = {} ) => {
	const element = document.querySelector( selector );

	// Exit if element doesn't exist.
	if ( ! element ) {
		return;
	}

	// Import dependencies.
	const { default: Swiper } = await import( /* webpackChunkName: "swiper" */ 'swiper' );
	const { Navigation, Thumbs, Keyboard } = await import( 'swiper/modules' );

	// Create Swiper instance.
	const swiper = new Swiper( element, {
		modules: [ Navigation, Thumbs, Keyboard ],
		...options,
	} );

	// Return Swiper.
	return swiper;
};

export default createSwiper;
