// Search specs
if ( $( '#search-specs' ).length > 0 ) {
	  const teamsWrapper = $( '.list-border' );
	  const teamsAssoc = [];
	const search_specs = $( '#search-specs' );

	  $.each( teamsWrapper.children( '.searchItem' ), function() {
	    const objectItem = $( this );
	    if ( objectItem != '' ) {
	      teamsAssoc.push( {
	        obj: objectItem,
	        textItem: objectItem.text().toLowerCase(),
	      } );
	    }
	  } );

	  search_specs.on( 'keyup', function() {
	    const search = $( this ).val().toLowerCase();
	    if ( search.trim() != '' ) {
	      $.each( teamsAssoc, function() {
	        const textContent = this.textItem;
	        if ( textContent.indexOf( search ) >= 0 ) {
	          $( this.obj[ 0 ] ).addClass( 'highlight' );
	          $( this.obj[ 0 ] ).removeClass( 'no-highlight' );
	        } else {
	          $( this.obj[ 0 ] ).removeClass( 'highlight' );
	          $( this.obj[ 0 ] ).addClass( 'no-highlight' );
	        }
	      } );
	    } else {
	      $.each( teamsAssoc, function() {
	        // const textContent = this.textItem;
	        $( this.obj[ 0 ] ).removeClass( 'no-highlight' );
	        $( this.obj[ 0 ] ).removeClass( 'highlight' );
	      } );
	    }
	  } );

	  search_specs.on( 'keydown', function( event ) {
	    if ( event.code == 13 ) {
	      $( '.reveal-specs' ).animate( {
	        scrollTop: $( '.highlight' ).offset().top - scrollToTop,
	      }, 1000 );
	      return false;
	    }
	  } );
}
