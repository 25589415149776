/* global $ i18n_object */

/**
 * Import local dependencies.
 */
import { CONTROLLER_CACHE, doRequest } from '../helpers/request';
import debounce from '../helpers/debounce';
import {
	validateEmail,
	validateEmpty,
	validateMobile,
	validateZip,
} from '../helpers/regex';

$( document ).on( 'nfFormReady', () => {
	const licenseInput = document.querySelector( '#nf-field-97' );
	const brandInput = document.querySelector( '#nf-field-99' );
	const colorInput = document.querySelector( '#nf-field-132' );
	const typeInput = document.querySelector( '#nf-field-131' );

	if ( ! licenseInput || ! brandInput || ! colorInput || ! typeInput ) {
		return;
	}

	/**
	 * Get vehicle data from RDW.
	 *
	 * @param {KeyboardEvent} event
	 */
	const onLicenseEntered = async ( { target } ) => {
		// Abort previous request if it exists.
		if ( CONTROLLER_CACHE.has( target ) ) {
			CONTROLLER_CACHE.get( target ).abort();
			CONTROLLER_CACHE.delete( target );
		}
		const controller = new AbortController();
		CONTROLLER_CACHE.set( target, controller );

		// Make sure value is correct.
		let license = target.value.toUpperCase();
		license = license.replace( /[^a-zA-Z0-9]/g, '' ); // Remove all characters except a-z and 0-9.

		try {
			// Make request to RDW.
			const response = await fetch(
				`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${ license }`,
				{ signal: controller.signal }
			);

			// Make sure we have a 2xx status code.
			if ( ! response.ok ) {
				throw new Error( `${ response.statusText } (Response is not ok!)` );
			}

			// Get JSON response.
			const data = await response.json();

			// Handle data.
			if ( data.length > 0 ) {
				const { merk, handelsbenaming, eerste_kleur, voertuigsoort } = data[ 0 ]; // eslint-disable-line camelcase

				brandInput.value = `${ merk } ${ handelsbenaming }`;
				colorInput.value = eerste_kleur; // eslint-disable-line camelcase
				typeInput.value = voertuigsoort;
			} else {
				brandInput.value = '';
				colorInput.value = '';
				typeInput.value = '';
			}
		} catch ( error ) {
			console.error( error ); // eslint-disable-line no-console
		}
	};

	licenseInput.addEventListener( 'input', debounce( onLicenseEntered ), false );

	if ( licenseInput.value !== '' ) {
		licenseInput.addEventListener( 'check', debounce( onLicenseEntered ), false );
		licenseInput.dispatchEvent( new Event( 'check' ) );
	}
} );

$( function() {
	if ( document.body.classList.contains( 'woocommerce-checkout' ) || document.body.classList.contains( 'woocommerce-account' ) ) {
		$( 'body' ).on( 'init_checkout', function() {
			changeBillingType();
		} );

		if ( $( '#billing_company_field' ).length > 0 ) {
			changeBillingType();
			$( 'input[name=billing_order_type]' ).on( 'change', function() {
				changeBillingType( this );
				// $('body').trigger('update_checkout');
			} );
		}

		function changeBillingType( field ) {
			let val;
			if ( typeof field !== 'undefined' ) {
				val = $( field ).val().toLowerCase();
			} else if ( $( 'input[name=billing_order_type]:checked' ).length > 0 ) {
				val = $( 'input[name=billing_order_type]:checked' ).val().toLowerCase();
			} else {
				$( 'input[name=billing_order_type]' ).first().prop( 'checked', true );
				val = $( 'input[name=billing_order_type]' ).first().val().toLowerCase();
			}
			const bussinessFields = $( '#billing_company_field' );
			if ( val === 'zakelijk' ) {
				bussinessFields.show( 0, function() {
					$( this ).addClass( 'validate-required' );
					$( this ).find( 'input' ).val( null );
					$( this ).find( '.fl-wrap' ).removeClass( 'fl-is-active' );
				} );
			} else {
				bussinessFields.hide( 0, function() {
					$( this ).removeClass( 'validate-required' );
					$( this ).removeClass( 'woocommerce-validated' );
					$( this ).find( 'input' ).val( 'no' );
				} );
			}
			$( 'input[name=shipping_company]' ).val( '' );
		}
	}
} );

/**
 * Add warranty service to cart.
 */
( () => {
	const checkoutBtn = document.querySelector( '.checkout-button' );

	// Exit there is no button.
	if ( ! checkoutBtn ) {
		return;
	}

	/**
	 * Add warrenty to cart.
	 *
	 * @param {MouseEvent} event
	 */
	const onMoveToCheckout = async ( event ) => {
		// Exit if no services are selected.
		const service = document.querySelector( '.service-checkbox:checked' );
		if ( ! service ) {
			return;
		}

		// Stop page load.
		event.preventDefault();

		// Get data.
		const { target } = event;
		const { href } = target;
		const { nonce } = target.dataset;

		// Prepare request body.
		const body = new FormData();
		body.set( 'action', 'add_warranty' );
		body.set( 'mk_security', nonce );

		// Prepare service.
		const { warrantyId, price } = service.dataset;
		body.append( 'service_id', warrantyId );
		body.append( 'service_price', price );

		try {
			await doRequest( { body } );
		} catch ( error ) {
			console.error( error ); // eslint-disable-line no-console
		} finally {
			const url = new URL( href );
			url.searchParams.set( 't', Date.now() );
			window.location = url;
		}
	};

	checkoutBtn.addEventListener( 'click', onMoveToCheckout );
} )();

const toggleErrorState = ( field, isError = true ) => {
	if ( isError ) {
		field.classList.add( 'error' );
		field.parentNode.classList.add( 'error' );
	} else {
		field.classList.remove( 'error' );
		field.parentNode.classList.remove( 'error' );
	}
};

/**
 * Toggle checkout screen
 *
 * @param {string} showId ID of element to show.
 * @param {string} hideId ID of element to hide.
 */
const toggleScreen = ( showId, hideId ) => {
	window.scrollTo( {
		top: 0,
	} );
	const show = document.getElementById( showId );
	const hide = document.getElementById( hideId );

	if ( show && hide ) {
		show.classList.remove( 'hide' );
		hide.classList.add( 'hide' );
	}
};

/**
 * Validate checkout fields
 */
( () => {
	const stepOneBtn = document.querySelector( '#js-next-step-1' );
	const stepTwoBtn = document.querySelector( '#js-next-step-2' );
	const stepThreeBtn = document.querySelector( '#js-next-final' );

	const prevOneBtn = document.querySelector( '#js-prev-step' );
	const prevTwoBtn = document.querySelector( '#js-prev-step-1' );
	const prevThreeBtn = document.querySelector( '#js-prev-step-2' );

	const errorWrapper = document.querySelector( '#showCheckoutErrors' );
	const errorContractWrapper = document.querySelector( '#acceptContractError' );

	const differentAddress = document.querySelector(
		'#ship-to-different-address-checkbox'
	);

	// Exit if checkout buttons don't exist.
	if ( ! stepOneBtn || ! stepTwoBtn || ! stepThreeBtn ) {
		return;
	}

	// Exit if checkout buttons don't exist.
	if ( ! prevOneBtn || ! prevTwoBtn || ! prevThreeBtn ) {
		return;
	}

	// const createContract = async ( form, nonce ) => {
	// 	// Exit if no form is given.
	// 	if ( ! form ) {
	// 		return;
	// 	}

	// 	$( '.card-form-contact-details' ).addClass( 'is-loading' );

	// 	try {
	// 		errorWrapper.innerHTML = '';
	// 		errorWrapper.classList.add( 'hide' );

	// 		const body = new FormData( form );
	// 		body.set( 'action', 'create_contract' );
	// 		body.set( 'mk_security', nonce );

	// 		const pdfUrl = await doRequest( { body } );

	// 		toggleScreen( 'order_review', 'customer_details' );
	// 		toggleScreen( 'js-next-step-2', 'js-next-step-1' );
	// 		toggleScreen( 'js-prev-step-1', 'js-prev-step' );

	// 		const link = document.getElementById( 'downloadContract' );
	// 		link.setAttribute( 'href', pdfUrl );
	// 	} catch ( error ) {
	// 		errorWrapper.classList.remove( 'hide' );
	// 		errorWrapper.insertAdjacentHTML(
	// 			'afterbegin',
	// 			`<li>${ i18n_object.unknown_problem }</li>` // eslint-disable-line camelcase
	// 		);

	// 		console.error( error );
	// 	} finally {
	// 		$( '.card-form-contact-details' ).removeClass( 'is-loading' );
	// 	}
	// };

	/**
	 * Validate form input fields.
	 *
	 * @param {MouseEvent} event
	 */
	const onCheckInputs = () => {
		// Get all elements.
		const billingFields = document.querySelectorAll( `
      .woocommerce-billing-fields__field-wrapper input:not([type="submit"]),
      .woocommerce-billing-fields__field-wrapper select
    ` );
		const isPrivate = document.querySelector( '#billing_order_type_particulier' ).checked;

		// Exit if no fields are present.
		if ( billingFields.length <= 0 ) {
			return;
		}

		// Add shipping fields if checkbox is checked.
		let allFields = [ ...billingFields ];
		if ( differentAddress?.checked ) {
			const shippingFields = document.querySelectorAll( `
        .woocommerce-shipping-fields__field-wrapper input:not([type="submit"]),
        .woocommerce-shipping-fields__field-wrapper select
      ` );

			if ( shippingFields.length > 0 ) {
				allFields = [ ...allFields, ...shippingFields ];
			}
		}

		const errors = [];

		// Show delivery option
		const selectedDelivery = document.querySelector(
			'[name="delivery"]:checked'
		);
		const deliveryOutput = document.querySelector( '#deliveryStyle' );
		deliveryOutput.textContent =
      selectedDelivery.value === 'delivery'
      	? i18n_object.delivery
      	: i18n_object.pickup; // eslint-disable-line camelcase

		// Validate each field.
		for ( const field of allFields ) {
			// Add value to check field (for later use).
			const check = document.getElementById( `${ field.id }_check` );
			if ( check ) {
				check.textContent = field.value;
			}

			// Check for an empty value.
			let isValid = validateEmpty( field.value );

			// Validate zipcode.
			if ( field.id === 'billing_postcode' || field.id === 'shipping_postcode' ) {
				isValid = validateZip( field.value );
			}

			// Validate email.
			if ( field.id === 'billing_email' ) {
				isValid = validateEmail( field.value );
			}

			// Validate phone.
			if ( field.id === 'billing_phone' ) {
				isValid = validateMobile( field.value );
			}

			// Skip company for private checkout since it isn't required.
			// if (
			// 	isPrivate &&
			//   ( field.id === 'billing_company' || field.id === 'shipping_company' )
			// ) {
			// 	isValid = true;
			// }

			// Toggle error based on validation state.
			if ( ! isValid ) {
				toggleErrorState( field );
				errors.push(
					`<li><strong>${ field.placeholder }:</strong> ${ i18n_object.enter_valid_value }</li>`
				); // eslint-disable-line camelcase
			} else {
				toggleErrorState( field, false );
			}
		}

		errorWrapper.innerHTML = '';
		if ( errors.length <= 0 ) {
			// Hide errors.
			errorWrapper.classList.add( 'hide' );
			// createContract( errorWrapper.closest( 'form' ), target.dataset.nonce );

			const checks = document.querySelectorAll( '[data-check]' );
			for ( const check of checks ) {
				check.classList.add( 'hide' );

				if ( check.dataset.check === 'private' && isPrivate ) {
					check.classList.remove( 'hide' );
				}

				if ( check.dataset.check === 'business' && ! isPrivate ) {
					check.classList.remove( 'hide' );
				}
			}

			toggleScreen( 'order_review', 'customer_details' );
			toggleScreen( 'js-next-step-2', 'js-next-step-1' );
			toggleScreen( 'js-prev-step-1', 'js-prev-step' );
		} else {
			// Display errors
			errorWrapper.classList.remove( 'hide' );
			errorWrapper.insertAdjacentHTML( 'afterbegin', errors.join( '' ) );

			window.scrollTo( {
				top: 0,
				behavior: 'smooth',
			} );
		}
	};

	stepOneBtn.addEventListener( 'click', onCheckInputs, false );

	/**
	 * Hide shipping methods when delivering to different address.
	 *
	 * @param {MouseEvent} event
	 */
	const onCheckAddress = ( { currentTarget } ) => {
		const shippingMethods = document.querySelectorAll( '[name="delivery"]' );

		// Show all shipping methods.
		if ( ! currentTarget.checked ) {
			for ( const method of shippingMethods ) {
				method.parentElement.classList.remove( 'hide' );
			}

			return;
		}

		// Hide all shipping methods except delivery.
		for ( const method of shippingMethods ) {
			if ( method.id !== 'delivery-customer' ) {
				method.parentElement.classList.add( 'hide' );
				continue;
			}

			method.checked = true;
		}
	};

	if ( differentAddress ) {
		differentAddress.addEventListener( 'change', onCheckAddress, false );
	}

	/**
	 * Validate check fields.
	 *
	 * @param {MouseEvent} event
	 */
	const onCheckDetails = async ( { currentTarget } ) => {
		// const accept = document.getElementById( 'accept-contract' );
		const checks = [ ...document.querySelectorAll( '[data-check]' ) ].filter( ( check ) => ! check.classList.contains( 'hide' ) );
		const allChecked = checks.every( ( check ) => check.firstElementChild.checked );
		errorContractWrapper.innerHTML = '';

		// Check all checkboxes.
		if ( ! allChecked ) {
			errorContractWrapper.classList.remove( 'hide' );
			errorContractWrapper.insertAdjacentHTML(
				'afterbegin',
				`<li>${ i18n_object.agree_terms }</li>` // eslint-disable-line camelcase
			);
			window.scrollTo( {
				top: 0,
				behavior: 'smooth',
			} );
			return;
		}

		prevTwoBtn.disabled = true;

		errorContractWrapper.classList.add( 'hide' );

		const delivery = document.querySelector( '[name="delivery"]:checked' );

		const body = new FormData();
		body.set( 'action', 'store_order_meta_in_session' );
		body.set( 'mk_security', currentTarget.dataset.nonce );
		body.set( 'delivery_type', delivery.value );

		await doRequest( { body } );

		toggleScreen( 'payment-wrapper', 'order_review' );
		toggleScreen( 'js-next-final', 'js-next-step-2' );
		toggleScreen( 'js-prev-step-2', 'js-prev-step-1' );

		prevTwoBtn.disabled = false;
	};
	stepTwoBtn.addEventListener( 'click', onCheckDetails, false );

	prevTwoBtn.addEventListener(
		'click',
		() => {
			toggleScreen( 'customer_details', 'order_review' );
			toggleScreen( 'js-next-step-1', 'js-next-step-2' );
			toggleScreen( 'js-prev-step', 'js-prev-step-1' );
		},
		false
	);

	prevThreeBtn.addEventListener(
		'click',
		() => {
			toggleScreen( 'order_review', 'payment-wrapper' );
			toggleScreen( 'js-next-step-2', 'js-next-final' );
			toggleScreen( 'js-prev-step-1', 'js-prev-step-2' );
		},
		false
	);

	/**
	 * Submit order
	 */
	const onSubmitOrder = () => {
		// Don't allow to go back, because order will still be placed.
		prevThreeBtn.disabled = true;

		const placeOrderBtn = document.getElementById( 'place_order' );
		placeOrderBtn?.click();
	};
	stepThreeBtn.addEventListener( 'click', onSubmitOrder, false );

	/**
	 * Prevent submitting via enter key.
	 */
	$( 'form.woocommerce-checkout' ).keypress( function( e ) {
		// Enter key
		if ( e.which === 13 ) {
			onCheckInputs();
			return false;
		}
	} );
} )();

( () => {
	const particulierMessage = document.querySelector( '.grey-particulier' );
	const rows = document.querySelectorAll( '#ship-to-different-address, .form-row:not(.grey-particulier, #billing_order_type_field)' );
	const nextStep = document.querySelector( '#js-next-step-1' );
	const delivery = document.querySelector( '.woocommerce-additional-fields' );
	const types = document.querySelectorAll( '[name="billing_order_type"]' );

	if ( types.length <= 0 || ! particulierMessage ) {
		return;
	}

	/**
	 * Toggle particulier text on type change.
	 *
	 * @param {Event} event
	 */
	const onTypeChange = ( { target } ) => {
		// Exit if it is not enabled.
		if ( particulierMessage.dataset.enable !== '1' ) {
			return;
		}

		// Toggle hidden state.
		if ( target.value === 'particulier' ) {
			particulierMessage.classList.remove( 'hide' );
			delivery.classList.add( 'hide' );
			nextStep.disabled = true;
		} else {
			particulierMessage.classList.add( 'hide' );
			delivery.classList.remove( 'hide' );
			nextStep.disabled = false;
		}

		// Toggle rows.
		for ( const row of rows ) {
			if ( target.value === 'particulier' ) {
				row.classList.add( 'hide' );
			} else {
				row.classList.remove( 'hide' );
			}
		}
	};

	$( '[name="billing_order_type"]' ).on( 'click', onTypeChange );
} )();
