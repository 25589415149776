/* global $ */

/**
 * Import local dependencies.
 */
import { CONTROLLER_CACHE, doRequest, updateURLParams } from '../helpers/request';
// import { createCookie } from '../helpers/cookies';
import { LAZYLOAD_UPDATE_EVENT } from './lazyload';
import { Setfavorites } from './favorites';
import { SetCompares } from './comparison';

/**
 * Define global variables.
 */
const LOADER = document.querySelector( '.loader.large' );
const GRID = document.querySelector( '.grid-x.products' );
const EXTRA_FILTERS = document.querySelector( '.extra-filters' );
const EXTERNAL_IDS = [ 'posts-per-page', 'orderby' ];

/**
 * Get FormData for fields outside of current form.
 *
 * @return {Array<string[]>} Array of key value pairs.
 */
const getExternalFormData = () => {
	return EXTERNAL_IDS.flatMap( ( id ) => {
		const data = $( `#${ id }` ).select2( 'data' ); // We are forced to use jQuery since select2 depends on it.

		if ( ! data ) {
			return null;
		}

		return data.map( ( { id: value, element } ) => [ element.parentElement.name, value ] );
	} ).filter( Boolean );
};

/**
 * Filter output on input change.
 *
 * @param {JQuery.Event}    event
 * @param {HTMLFormElement} form
 */
const onChangeFilter = async ( event, form ) => {
	const { nonce } = form.dataset;
	const { href } = window.location;

	// Show loader.
	LOADER.classList.remove( 'hide' );

	// Abort previous request if it exists.
	if ( CONTROLLER_CACHE.has( form ) ) {
		CONTROLLER_CACHE.get( form ).abort();
		CONTROLLER_CACHE.delete( form );
	}
	const controller = new AbortController();
	CONTROLLER_CACHE.set( form, controller );

	// Create request body.
	const body = new FormData( form );

	body.set( 'action', 'request_cars' );
	body.set( 'base', href );
	body.set( 'mk_security', nonce );

	// Get data outside of current form.
	const externalData = getExternalFormData();
	for ( const datapair of externalData ) {
		body.append( ...datapair );
	}

	try {
		// Make request.
		const data = await doRequest( { body }, controller );
		// console.log( data ); // eslint-disable-line no-console

		// Update values.
		updateURLParams( data.filters );
		createBubbleFilters( data.filters );
		updateCarCount( data.total );
		updateFilterCount( data.filters, controller, nonce );
		updatePagination( GRID, data.pagination );

		// Update output.
		GRID.innerHTML = data.html;
		Setfavorites();
		SetCompares();

		// Hide loader.
		LOADER.classList.add( 'hide' );
	} catch ( error ) {
		// Only hide loader if the request was not aborted.
		if ( error.message !== 'The user aborted a request.' ) {
			LOADER.classList.add( 'hide' );
		}
		console.error( error.message ); // eslint-disable-line no-console
	} finally {
		form.dispatchEvent( LAZYLOAD_UPDATE_EVENT );

		// Synchronize mobile and desktop filter.
		const { target } = event;
		const fields = document.querySelectorAll( `[name="${ target.name }"]` );

		for ( const field of fields ) {
			$( field ).val( target.value ); // Select new value for select2 element.
			$( field ).trigger( 'change.select2' ); // Update selection.
		}

		// Clear model if brand changes.
		const { tax } = target.dataset;
		if ( target.classList.contains( 'js-select' ) && tax === 'product_cat' ) {
			const bubble = document.querySelector( '.button-filter[data-tax="model"]' );
			bubble?.click();
		}

		// Hide clear all btn
		const clearAllBtn = document.querySelector( '.clear-all-bubbles' );
		if ( clearAllBtn.previousElementSibling.children.length === 0 ) {
			clearAllBtn.classList.add( 'hide' );
		}
	}
};

/**
 * Update WooCommerce pagination.
 *
 * @param {HTMLElement} grid       Product grid element.
 * @param {string}      pagination Pagination HTML string.
 */
const updatePagination = ( grid, pagination ) => {
	const element = document.querySelector( '.woocommerce-pagination' );

	if ( element ) {
		element.remove();
	}

	grid.insertAdjacentHTML( 'afterend', pagination );
};

/**
 * Update filter count
 *
 * @param {Object}          filters
 * @param {AbortController} controller
 * @param {string}          nonce
 */
const updateFilterCount = async ( filters, controller, nonce ) => {
	const filterElements = [ ...document.querySelectorAll( '.dynamic-count' ) ];

	const requests = filterElements.map( ( filter ) => {
		// Get options
		const select = filter.querySelector( 'select' );
		const options = filter.querySelectorAll( 'option' );
		const terms = options.length > 0 ? [ ...options ].map( ( option ) => option.value ).filter( Boolean ) : [];

		// Show loading state
		filter.classList.add( 'loading' );
		filter.firstElementChild.classList.remove( 'hide' );

		// Create request body.
		const body = new FormData();
		body.set( 'action', 'update_count' );
		body.set( 'mk_security', nonce );
		body.set( 'filters', JSON.stringify( filters ) );
		body.set( 'tax', select.dataset.tax ?? null );
		body.set( 'terms', JSON.stringify( terms ) );

		return doRequest( { body }, controller );
	} );

	try {
		const data = await Promise.all( requests );

		filterElements.forEach( ( filter, index ) => {
			const select = filter.querySelector( 'select' );
			const counts = Object.entries( data[ index ] );

			$( select ).find( 'option[value]' ).each( function( i ) {
				// Regex:
				// \( = opening bracket.
				// \d+ = any digit, one or more.
				// \) = closing bracket.
				$( this ).text( $( this ).text().replace( /\(\d+\)/, `(${ counts[ i ][ 1 ] })` ) );
				if ( filter.dataset.label === 'Model' ) {
					const url = new URL( window.location );
					const brand = url.searchParams.get( 'product_cat' );

					if ( $( this ).data( 'parent' ) === brand ) {
						$( this ).prop( 'disabled', false );
					} else {
						$( this ).prop( 'disabled', true );
					}
				}
			} );
			$( select ).select2().trigger( 'change.select2' );

			// Hide loaders.
			for ( const element of filterElements ) {
				element.classList.remove( 'loading' );
				element.firstElementChild.classList.add( 'hide' );
			}
		} );
	} catch ( error ) {
		// Only hide loader if the request was not aborted.
		if ( error.message !== 'The user aborted a request.' ) {
			for ( const element of filterElements ) {
				element.classList.remove( 'loading' );
				element.firstElementChild.classList.add( 'hide' );
			}
		}

		document.dispatchEvent( LAZYLOAD_UPDATE_EVENT );
		console.error( error.message ); // eslint-disable-line no-console
	}
};

/**
 * Filter cars.
 *
 * @param {HTMLFormElement} form Form associated with the filters.
 */
export default ( form ) => {
	// We are forced to use jQuery to listen to the events dispatched by select2.
	$( form ).on( 'change', ( event ) => onChangeFilter( event, form ) );

	// Also listen to external form elements.
	for ( const id of EXTERNAL_IDS ) {
		$( `#${ id }` ).on( 'change', ( event ) => onChangeFilter( event, form ) );
	}

	// Convert GET variables to bubbles.
	const params = new URL( window.location ).searchParams;
	createBubbleFilters( Object.fromEntries( params.entries() ) );
};

/**
 * Update car count.
 *
 * @param {number} num Count to display.
 */
const updateCarCount = ( num ) => {
	const counters = document.querySelectorAll( '.car-count' );

	for ( const counter of counters ) {
		counter.textContent = num;
		counter.dataset.allcars = num;
		counter.parentNode.innerHTML = counter.outerHTML + ( parseInt( num ) === 1 ? ' resultaat' : ' resultaten' );
	}
};

/**
 * Format bubble value based on taxonomy.
 *
 * @param {string} key
 * @param {string} value
 *
 * @return {string} Formatted value.
 */
const formatBubbleValue = ( key, value ) => {
	switch ( key ) {
		case 'price_min':
		case 'price_max':
			return new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 } ).format( value );
		case 'mileage':
			return new Intl.NumberFormat( 'nl-NL', { style: 'unit', unit: 'kilometer', minimumFractionDigits: 0 } ).format( value );
		default:
			return value;
	}
};

/**
 *  Create bubble filters.
 *
 * @param {Object<string, string>} filters
 */
export const createBubbleFilters = ( filters ) => {
	// Clear current filters.
	if ( EXTRA_FILTERS ) {
		EXTRA_FILTERS.innerHTML = '';
	}

	const clearAllBtn = document.querySelector( '.clear-all-bubbles' );

	// Append filters.
	for ( const [ key, value ] of Object.entries( filters ) ) {
		// Skip top filters.
		if ( key === 'total_posts' || key === 'order' ) {
			continue;
		}

		// Skip if element has an empty value.
		if ( value === '' ) {
			continue;
		}

		clearAllBtn.classList.remove( 'hide' );

		const element = document.querySelector( `[name="${ key }"]` );
		let label = element?.dataset?.label || value; // Get custom label if it is set.

		// If its a select, get label from option instead.
		if ( element.tagName === 'SELECT' ) {
			const option = element.querySelector( 'option:checked' );
			label = option?.dataset?.label || value; // Get custom label if it is set.
		}
		// If its min_seats or max_seats, add 'Min. zitplaatsen: ' or 'Max. zitplaatsen: ' to the bubble.
		if ( key === 'seats_min' ) {
			label = `Min. zitplaatsen: ${ label }`;
		}
		if ( key === 'seats_max' ) {
			label = `Max. zitplaatsen: ${ label }`;
		}

		// Insert bubble.
		EXTRA_FILTERS.insertAdjacentHTML( 'beforeend',
			`<button
        type="button"
        class="button button-filter filtering-bubble"
        data-tax="${ key }"
      >
        ${ formatBubbleValue( key, label ) }  <i class="icon icon-close" aria-hidden="true"></i>
      </button>`
		);
	}
};

/**
 * Clear clicked bubble filter
 *
 * @param {MouseEvent} event
 */
const onBubbleClicked = ( { currentTarget, target } ) => {
	const btn = target.closest( 'button' );
	const parent = btn.parentElement;

	// Exit if button doesn't exist.
	if ( ! btn ) {
		return;
	}

	// Single filter.
	if ( btn.classList.contains( 'filtering-bubble' ) ) {
		const { tax } = btn.dataset;

		// Also remove model when brand is deleted.
		if ( tax === 'product_cat' ) {
			const bubble = document.querySelector( '.button-filter[data-tax="model"]' );
			bubble?.click();
		}

		$( `select[data-tax="${ tax }"]` ).val( null ).trigger( 'change' ); // We are forced to use jQuery since select2 depends on it.
		$( `input[data-tax="${ tax }"]` ).click();

		// Remove button.
		btn.remove();
	}

	// Clear all filters.
	if ( btn.classList.contains( 'clear-all-bubbles' ) ) {
		const bubbles = currentTarget.querySelectorAll( '.filtering-bubble' );
		for ( const bubble of bubbles ) {
			bubble.click();
		}
	}
};

/**
 * Clear bubble filters.
 *
 * @param {HTMLElement} element Element containing our bubble filters.
 */
export const clearBubbleFilter = ( element ) => {
	if ( ! element ) {
		return;
	}

	element.addEventListener( 'click', onBubbleClicked, false );
};

/**
 * Fix autofocus select2 (does not work for multiselect).
 */
$( document ).on( 'select2:open', ( event ) => {
	setTimeout( () => {
		document.querySelector( '.select2-search__field' ).focus();
	}, 50 ); // Small delay so because of animations.
} );
