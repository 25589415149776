/**
 * Create Plyr with lazy loading.
 *
 * @param {string|HTMLElement} selector
 * @param {Plyr.Options}       options
 *
 * @return {Plyr|undefined} Plyr instance.
 */
const createPlyr = async ( selector, options = {} ) => {
	const element = selector instanceof HTMLElement ? selector : document.querySelector( selector );

	// Exit if element doesn't exist.
	if ( ! element ) {
		return;
	}

	// Import dependencies.
	const { default: Plyr } = await import( /* webpackChunkName: "plyr" */ 'plyr' );

	// Create Plyr instance.
	const plyr = new Plyr( element, { ...options } );

	// Return Plyr.
	return plyr;
};

export default createPlyr;
