/**
 * Import internal dependencies.
 */
import { doRequest } from '../helpers/request';
import { LAZYLOAD_UPDATE_EVENT } from './lazyload';
import { Setfavorites } from './favorites';

//makes comparecounter in the navbar
const navbar = document.querySelector( '.main-nav' );
if ( navbar ) {
	const compareHeaderItem = document.createElement( 'span' );
	compareHeaderItem.classList.add( 'nbr-count', 'compare', 'large' );
	compareHeaderItem.textContent = 0;
	document.querySelector( '.menu-item-icon a' ).append( compareHeaderItem.cloneNode( true ) );
}
const compareCounterHeader = document.querySelector( '.nbr-count.compare.large' );
const mobileCounter = document.querySelector( '.button-compare' );

/**
 * adds compared car to localstorage
 *
 * @param {string} id
 */
export function addComparedCarToStorage( id ) {
	const storedComparedCars = getStoredComparesCars();

	if ( ! storedComparedCars.includes( id ) ) {
		storedComparedCars.push( id );
		localStorage.setItem( 'compare-cars', JSON.stringify( storedComparedCars ) );
	}

	return storedComparedCars;
}

/**
 * Removes favorized cars
 *
 * @param {string} id
 */
export function removeComparedCarFromStorage( id ) {
	const storedComparedCars = getStoredComparesCars();

	const result = storedComparedCars.filter( ( carId ) => carId !== id );
	localStorage.setItem( 'compare-cars', JSON.stringify( result ) );

	amountOfcompares();
	return result;
}

/**
 * set Compared from localstorage
 *
 */
export function SetCompares() {
	const storedcomparedCars = getStoredComparesCars();
	for ( const storedcomparedCar of storedcomparedCars ) {
		const element = document.querySelector( `.compare[data-car="${ storedcomparedCar }"]` );
		if ( element?.checked === false ) {
			element.checked = true;
		}
	}

	amountOfcompares();
}

/**
 * Stores all for cars being compared
 *
 */
export function getStoredComparesCars() {
	const storedComparedCars = localStorage.getItem( 'compare-cars' );

	if ( ! storedComparedCars ) {
		return [];
	}
	return JSON.parse( storedComparedCars );
}

/**
 * counts Compared
 *
 */
export function CompareCounter( ) {
	if ( JSON.parse( localStorage.getItem( 'compare-cars' ) )?.length ) {
		const countAll = JSON.parse( localStorage.getItem( 'compare-cars' ) ).length;

		if ( navbar ) {
			compareCounterHeader.classList.add( 'is-active' );
			mobileCounter.classList.add( 'is-active' );
		}
		const counter = document.querySelectorAll( '.nbr-count.compare' );
		for ( const count of counter ) {
			count.textContent = countAll;
		}
	}
}

/**
 * Stores amount of compared
 *
 */
export function amountOfcompares() {
	const storedAmount = getStoredComparesCars().length;
	const counterCompare = document.querySelectorAll( '.nbr-count.compare' );
	const specialCounter = document.querySelector( '.comparePageCount' );

	const compares = 0;
	if ( storedAmount !== null ) {
		const amountcompares = storedAmount ?? compares;
		if ( amountcompares <= 0 ) {
			compareCounterHeader?.classList.remove( 'is-active' );
			mobileCounter?.classList.remove( 'is-active' );
			if ( specialCounter ) {
				specialCounter.classList.add( 'hide' );
			}

			for ( const count of counterCompare ) {
				count.textContent = amountcompares;
			}

			return;
		}
		for ( const count of counterCompare ) {
			count.textContent = amountcompares;
		}
		if ( navbar ) {
			compareCounterHeader.classList.add( 'is-active' );
			mobileCounter.classList.add( 'is-active' );
		}
		return JSON.parse( amountcompares );
	} else if ( ! document.querySelectorAll( '.added' ).length ) {
		const amountcompares = compares;
		for ( const count of counterCompare ) {
			count.textContent = amountcompares;
			mobileCounter.classList.remove( 'is-active' );
			compareCounterHeader.classList.remove( 'is-active' );
			if ( specialCounter ) {
				specialCounter.classList.add( 'hide' );
			}
		}
	}
}

/**
 * Eventlistener for comparing car on car overview and popular cars
 */
( () => {
	const productWrapper = document;

	//checks page on load for amount of comparison
	SetCompares();

	//checks which comparison is pressed and what the attached car is
	productWrapper.addEventListener( 'change', ( event ) => {
		const compareBtn = event.target.closest( '.compare' );

		if ( ! compareBtn ) {
			return;
		}
		const data = compareBtn.getAttribute( 'data-car' );

		if ( typeof ( Storage ) !== 'undefined' ) {
			event.preventDefault();
			const countAll = amountOfcompares();
			const counter = getStoredComparesCars().length;
			//checks if there is amount of compared cars
			if ( countAll <= 0 ) {
				window.localStorage.clear();
				amountOfcompares( );
			} else if ( counter < 3 ) {
				addComparedCarToStorage( data );
				CompareCounter();
			} else {
				compareBtn.checked = false;
			}

			//checks if comparison has already been checked
			if ( ! compareBtn.checked ) {
				removeComparedCarFromStorage( data );
			}
		}
	}, false );
} )();

/**
 * Eventlistener for comparing car on compare overview
 */
( () => {
	const productWrapper = document.querySelector( '.compare-grid' );
	if ( ! productWrapper ) {
		return;
	}
	//checks page on load for amount of comparison
	SetCompares();

	//checks which comparison is pressed and what the attached car is
	productWrapper.addEventListener( 'click', ( event ) => {
		const buttonOutside = event.target.closest( '.card-delete' );
		const buttonInside = event.target.closest( '.icon' );
		if ( event.target === buttonInside || event.target === buttonOutside ) {
			const compareBtn = event.target.closest( '.card-delete' );
			const card = event.target.closest( '.card-product-car.is-small' );
			event.preventDefault( );
			event.stopImmediatePropagation();

			if ( ! compareBtn ) {
				return;
			}
			const data = compareBtn.getAttribute( 'data-car' );
			const details = document.querySelector( `#grid-car-details [data-car="${ data }"]` );
			const buttons = document.querySelector( `#grid-car-buttons [data-car="${ data }"]` );

			if ( typeof ( Storage ) !== 'undefined' ) {
				const countAll = amountOfcompares();

				//checks if there is amount of compared cars
				if ( countAll <= 0 ) {
					window.localStorage.clear();
					amountOfcompares( );
				}

				//checks if comparison has already been checked
				removeComparedCarFromStorage( data );

        //Refresh counters
        CompareCounter();
				if ( document.querySelector( '.compare-wrapper' ) ) {
					card.classList.add( 'card-fadeout' );
					if ( countAll <= 3 && document.querySelectorAll( '.card-compare-empty' ).length !== 2 ) {
						setTimeout( () => {
							if ( details ) {
								details.innerHTML = '';
							}

							if ( buttons ) {
								buttons.innerHTML = '';
							}

							card.classList.remove( 'card-fadeout' );
							card.closest( '.cell' ).innerHTML = '<div class="card-compare-empty"><a class="button hollow" href="' + ajax_object.home_url + '/aanbod/" title="Overzicht aanbod">Auto toevoegen</a></div>';
						}, 450 );
					} if ( countAll <= 1 ) {
						if ( card ) {
							setTimeout( () => {
								if ( details ) {
									details.innerHTML = '';
								}

								if ( buttons ) {
									buttons.innerHTML = '';
								}

								card.closest( '.cell' ).remove();
								document.querySelector( '.comparePageCount' ).classList.add( 'hide' );
							}, 500 );
						}
					}
				}
			}
		}
	}, false );
} )();

/**
 * Add details to grid
 *
 * @param {string} id      ID of HTML grid element.
 * @param {Object} details Details to show.
 */
const appendDetailsToGrid = ( id, details ) => {
	const grid = document.getElementById( id );

	// Exit if grid doesn't exist.
	if ( ! grid ) {
		return;
	}

	grid.innerHTML = '';
	let isFirst = true;

	const storedCars = getStoredComparesCars();
	const values = Object.entries( details ).sort( ( a, b ) => storedCars.indexOf( a[ 0 ] ) - storedCars.indexOf( b[ 0 ] ) );

	for ( const [ carId, specs ] of values ) {
		let wrapper = `<div class="cell small-8 large-6 ${ isFirst ? 'large-offset-6' : '' }" data-car="${ carId }">`;

		for ( const detail of specs ) {
			const { name, value } = detail;

			wrapper += `
        <div class="label-data">
          <span class="label-small">${ name }</span>
          <span class="label-large">${ value }</span>
        </div>
      `;
		}

		wrapper += '</div>';

		grid.innerHTML += wrapper;

		isFirst = false;
	}
};

const appendOptionsToGrid = ( id, options ) => {
	const grid = document.getElementById( id );

	// Exit if grid doesn't exist.
	if ( ! grid ) {
		return;
	}

	grid.innerHTML = '';

	for ( const option in options ) {
		let wrapper = `
      <div class="grid-x grid-margin-x">
        <div class="cell small-8 large-6">${ option }</div>
    `;

		for ( const isAvailable of options[ option ] ) {
			wrapper += `
        <div class="cell small-8 large-6">
          <div class="label-data">
            ${ isAvailable ? '<span class="icon-check text-green"></span>' : '<span class="label-large">-</span>' }
          </div>
        </div>
      `;
		}

		wrapper += '</div>';

		grid.innerHTML += wrapper;
	}
};

const appendButtonsToGrid = ( id, cars ) => {
	const grid = document.getElementById( id );

	// Exit if grid doesn't exist.
	if ( ! grid ) {
		return;
	}

	grid.innerHTML = '';
	let isFirst = true;

	for ( const car of cars ) {
		let wrapper = `<div class="cell small-8 large-6 ${ isFirst ? 'large-offset-6' : '' }" data-car="${ car.post_id }">`;

		wrapper += `
      <a class="button primary expanded text-center" href="${ car.permalink }">
        Koop online
      </a>
      <a class="button hollow expanded text-center" href="/proefrit-aanvragen/?carid=${ car.hexon_nr }">
        Proefrit aanvragen
      </a>
    `;

		wrapper += '</div>';

		grid.innerHTML += wrapper;

		isFirst = false;
	}
};

/**
 * Get Comparison cars on Comparison page
 */
( async () => {
	const compareGrid = document.querySelector( '.compare-grid' );

	if ( ! compareGrid ) {
		return;
	}

	const { nonce } = compareGrid.dataset;

	const body = new FormData();
	body.set( 'action', 'request_comparison' );
	body.set( 'mk_security', nonce );

	//Gets posts from localstorage.
	const storedCars = getStoredComparesCars();

	for ( const storedCar of storedCars ) {
		body.append( 'post_id[]', storedCar );
	}

	try {
		const data = await doRequest( { body } );
		compareGrid.innerHTML = data.preview;

		appendDetailsToGrid( 'grid-car-details', data.details );
		appendDetailsToGrid( 'grid-car-maintenance', data.maintenance );
		appendOptionsToGrid( 'grid-car-options', data.options );
		appendButtonsToGrid( 'grid-car-buttons', data.cars );
	} catch ( error ) {
		// Silence error.
		console.log( error ); // eslint-disable-line no-console
	} finally {
		//Hide loader & set comparison with favorites
		SetCompares();
		Setfavorites();
		document.querySelector( '.loader.large' ).classList.add( 'hide' );

		// Fix images.
		compareGrid.dispatchEvent( LAZYLOAD_UPDATE_EVENT );
	}
} )();
