/**
 * Show active thumbnail on scroll.
 *
 * @param {IntersectionObserverEntryInit[]} entries
 */
const onIntersecion = ( entries ) => {
	for ( const entry of entries ) {
		const { target, isIntersecting } = entry;

		if ( isIntersecting ) {
			const id = target.id;
			const thumb = document.querySelector( `a[href="#${ id }"]` );
			const li = thumb.parentElement;
			const ul = li.parentElement;

			// Make all sibling elements inactive.
			for ( const sibling of ul.children ) {
				sibling.classList.remove( 'active' );
			}

			// Mark current element as active.
			li.classList.add( 'active' );

			const match = id.match( /(?=thumb(\d+))/, '' );
			const num = match?.[ 1 ] ?? 0;

			// Scroll thumbnails to match active slide.
			ul.scrollTo( {
				top: li.offsetHeight * ( parseInt( num ) - 1 ),
				behavior: 'smooth',
			} );
		}
	}
};

const reveals = document.querySelectorAll( '.reveal-media' );
for ( const reveal of reveals ) {
	// Create observer that triggers when an image is 50% visible.
	const observer = new IntersectionObserver( onIntersecion, {
		root: reveal,
		threshold: 0.5,
	} );

	// Add images to observe.
	const slides = document.querySelectorAll( 'div[data-magellan-target]' );
	for ( const slide of slides ) {
		observer.observe( slide );
	}
}

/**
 * Navigate to clicked thumbnail.
 *
 * @param {MouseEvent} event
 */
const onNavigateThumbs = ( event ) => {
	const { target, currentTarget } = event;
	const element = target.closest( '.button-prev, .button-next, .rs' );
	const li = currentTarget.querySelector( 'li.active' );

	// Exit if no element is found.
	if ( ! element ) {
		return;
	}

	// Show clicked image.
	if ( element.classList.contains( 'rs' ) ) {
		event.preventDefault();

		// Calculate scroll distance.
		const { hash } = new URL( element.href );
		const image = document.querySelector( hash );

		// Scroll to clicked image.
		image.scrollIntoView();

		return;
	}

	// Show previous image.
	if ( element.classList.contains( 'button-prev' ) ) {
		const prev = li.previousElementSibling;

		// Exit if there is no previous element.
		if ( ! prev ) {
			return;
		}

		prev.firstElementChild.click();
	}

	// Show next image.
	if ( element.classList.contains( 'button-next' ) ) {
		const next = li.nextElementSibling;

		// Exit if there is no next element.
		if ( ! next ) {
			return;
		}

		next.firstElementChild.click();
	}
};

const thumbnailWrappers = document.querySelectorAll( '.media-thumbs-wrapper' );
for ( const thumbnailWrapper of thumbnailWrappers ) {
	thumbnailWrapper.addEventListener( 'click', onNavigateThumbs, false );
}
