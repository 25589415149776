/* global $ */

// Swap car
// --------------
if ( $( '.card-product-car' ).length > 0 ) {
	const cartButton = $( '.product-bar #cart-button' );
	const swapButton = $( '.product-bar #swap-button' );

	$( '.swap-checkbox' ).on( 'click', function() {
		const checkbox = $( this );
		if ( $( this ).prop( 'checked' ) === true ) {
			$( cartButton ).addClass( 'loading' );
			setTimeout( function() {
				if ( checkbox.prop( 'checked' ) ) {
					$( cartButton ).addClass( 'hide' ).removeClass( 'loading' );
					$( swapButton ).removeClass( 'hide' );
				} else {
					$( cartButton ).removeClass( 'loading' );
				}
			}, 1200 );
		} else {
			$( swapButton ).addClass( 'loading' );

			setTimeout( function() {
				if ( checkbox.prop( 'checked' ) === false ) {
					$( swapButton ).addClass( 'hide' ).removeClass( 'loading' );
					$( cartButton ).removeClass( 'hide' );
				} else {
					$( swapButton ).removeClass( 'loading' );
				}
			}, 1200 );
		}
	} );
}
