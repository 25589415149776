/* global $ ajax_object */

/**
 * Import external dependencies.
 */
import FloatLabels from 'float-labels.js';
// import { gsap } from 'gsap';
import 'instant.page';

/**
 * Import internal dependencies.
 */
import Foundation from './_vendor/foundation/entries/foundation.js';
import './components/select';
import filterCars, { clearBubbleFilter } from './components/filter';
import createSwiper from './components/swiper';
import createPlyr from './components/plyr';
import './_vendor/maps/maps.js';
import 'sharer.js';

import './components/lazyload';
import './components/media-modal';
import './components/favorites';
import './components/comparison';
import './components/forms';
import './components/search-specs';
import './components/filter-home';
import './components/checkout';
import './components/cart';
import './components/product-single';

/**
 * Setup Foundation
 */

Foundation.Interchange.SPECIAL_QUERIES.smallretina =
  'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
Foundation.Interchange.SPECIAL_QUERIES.mediumretina =
  'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
Foundation.Interchange.SPECIAL_QUERIES.largeretina =
  'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
Foundation.Interchange.SPECIAL_QUERIES.xlargeretina =
  'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

$( document ).foundation();

/**
 * Setup Swiper props.
 */
const SWIPER_SHARED_PROPS = {
	slidesPerView: 1.2,
	spaceBetween: 20,
	threshold: 20,
	navigation: {
		nextEl: '.swiper-cars-next',
		prevEl: '.swiper-cars-prev',
	},
	breakpoints: {
		1023: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
	},
};

/**
 * Add plyr players if available
 */
const players = document.querySelectorAll( '.player' );
for ( const player of players ) {
	const settings = {
		controls: [
			'play-large',
			'play',
			'fullscreen',
			'current-time',
			'progress',
			'mute',
		],
	};
	createPlyr( player, settings );
}

/**
 * Open reveal.
 *
 * @param {MouseEvent} event
 */
const onOpenReveal = async ( { currentTarget } ) => {
	// Exit if no reveal ID is set.
	const { open, videoUrl } = currentTarget.dataset;

	if ( ! open ) {
		return;
	}

	// Exit if element doesn't exist.
	const reveal = document.querySelector( '.reveal-video' );
	if ( ! reveal ) {
		return;
	}

	$( reveal ).foundation( 'open' );

	const settings = {
		controls: [
			'play-large',
			'play',
			'fullscreen',
			'current-time',
			'progress',
			'mute',
		],
	};

	reveal.querySelector( 'iframe' )?.setAttribute( 'src', videoUrl );
	const plyr = await createPlyr( '.autoplayer', settings );

	plyr.on( 'ready', () => plyr.play() );
	$( reveal ).on( 'closed.zf.reveal', () => plyr.destroy() );
};

if ( $( '#maxNumberReveal' ).length > 0 ) {
	$( '#maxNumberReveal' ).foundation( 'open' );
}

/**
 * Autoplay Plyr video on open modal
 */
const openRevealBtns = document.querySelectorAll( '.open-reveal' );
for ( const btn of openRevealBtns ) {
	btn.addEventListener( 'click', onOpenReveal, false );
}

let productBar = document.querySelector( '.sticky-bar, .product-bar' );

if ( productBar ) {
	const footer = document.querySelector( '#footer' );
	if ( footer ) {
		footer.style.marginBottom = productBar.offsetHeight + 'px';
		addEventListener( 'resize', () => {
			productBar = document.querySelector( '.sticky-bar, .product-bar' );

			if ( footer.style.marginBottom !== productBar.offsetHeight ) {
				footer.style.marginBottom = productBar.offsetHeight + 'px';
			}
		} );
	}
}

if ( document.body.classList.contains( 'post-type-archive-product' ) ) {
	let filterBar = document.body.clientWidth < 992 ? document.querySelector( '.product-filter.hide-for-large' ) : document.querySelector( '.product-bar' );

	if ( filterBar ) {
		const footer = document.querySelector( '#footer' );
		if ( footer ) {
			footer.style.marginBottom = filterBar.offsetHeight + 'px';
			addEventListener( 'resize', () => {
				filterBar = document.body.clientWidth < 992 ? document.querySelector( '.product-filter.hide-for-large' ) : document.querySelector( '.product-bar' );

				if ( footer.style.marginBottom !== filterBar.offsetHeight ) {
					footer.style.marginBottom = filterBar.offsetHeight + 'px';
				}
			} );
		}
	}
}

/**
 * Create Swiper for cars.
 */
createSwiper( '.swiper-cars', SWIPER_SHARED_PROPS );

/**
 * Create Swiper for employees.
 */
createSwiper( '.swiper-employees', SWIPER_SHARED_PROPS );

/**
 * Create FloatLabels
 */
const floatlabels = new FloatLabels( '.woocommerce-checkout, .fl-form', {
	customEvent: null,
	customLabel: null,
	exclude: '.no-label',
	inputRegex: /email|number|password|search|tel|text|url/,
	prefix: 'fl-',
	prioritize: 'label',
	requiredClass: 'required',
	style: 2,
	transform: 'input, textarea',
} );
floatlabels.rebuild();

/**
 * Toggle button text for filter canvas.
 *
 * (We must use jQuery since it has a custom event layer that we cannot access via vanilla JS).
 *
 * @param {JQuery.Event} event
 */
const onChangeOffCanvasState = ( { target, type } ) => {
	const textElement = target.querySelector(
		'[data-toggle="desktop-filters"] span'
	);
	textElement.textContent = textElement.dataset[ type ];
};
$( '#desktop-filters' ).on( 'opened.zf.offCanvas', onChangeOffCanvasState );
$( '#desktop-filters' ).on( 'closed.zf.offCanvas', onChangeOffCanvasState );

/**
 * Filter cars via given form.
 */
filterCars( document.getElementById( 'car-filter-form' ) );
filterCars( document.getElementById( 'offCanvas-mobileFilters' ) );

/**
 */
clearBubbleFilter( document.querySelector( '.active-filters' ) );

/**
 * Toggle filter view.
 *
 * @param {MouseEvent} event
 */
const onToggleView = ( { target } ) => {
	// Exit if no button is pressed.
	const toggle = target.closest( 'button' );
	if ( ! toggle ) {
		return;
	}

	// Set active state.
	toggle.classList.add( 'active' );
	const { showList } = toggle.dataset;

	// Remove other active states.
	const btns = toggle.parentElement.children;
	for ( const btn of btns ) {
		// Skip current btn.
		if ( toggle === btn ) {
			continue;
		}

		btn.classList.remove( 'active' );
	}

	// Get product wrapper.
	const productWrapper = document.querySelector(
		'.woocommerce-products-wrapper'
	);
	if ( ! productWrapper ) {
		return;
	}

	// Toggle list view.
	if ( showList !== undefined ) {
		productWrapper.children[ 1 ].classList.add( 'medium-up-2', 'large-up-3' );
		productWrapper.classList.remove( 'list-view' );
	} else {
		productWrapper.children[ 1 ].classList.remove( 'medium-up-2', 'large-up-3' );
		productWrapper.classList.add( 'list-view' );
	}
};

/**
 * Toggle grid/list view.
 */
const viewBtnWrapper = document.querySelector( '.view-btn-wrapper' );
if ( viewBtnWrapper ) {
	viewBtnWrapper.addEventListener( 'click', onToggleView, false );
}

const reviewCounters = document.querySelectorAll( '.rating-count' );
if ( reviewCounters.length > 0 ) {
	for ( let i = 0; i < reviewCounters.length; i++ ) {
		reviewCounters[ i ].innerHTML = ajax_object.review_count; // eslint-disable-line camelcase
	}
}

window.onload = () => {
	// block transitions by load
	document.body.classList.remove( 'preload' );
};

/**
 * Login failed redirect
 */
const urlParams = new URLSearchParams( window.location.search );
const loginParameter = urlParams.get( 'login' );
if ( loginParameter === 'failed' ) {
	//check if foo parameter is set to anything
	$( '.fl-form' ).prepend(
		"<span class='dealer-login-failed'>gebruikersnaam of wachtwoord onjuist, probeer opnieuw.</span>"
	);
	$( '#dealer-dropdown' ).foundation( 'open' );
}

/**
 * Muddekok car description accordion
 */
const accordionButton = document.getElementById( 'mdk-accordion-button' );
const accordionContent = document.getElementById( 'mdk-accordion-content' );

if ( accordionButton && accordionContent ) {
	accordionButton.addEventListener( 'click', () => {
		const isOpen = accordionContent.getAttribute( 'aria-expanded' ) === 'true';
		accordionContent.setAttribute( 'aria-expanded', ! isOpen );
		accordionButton.textContent = isOpen ? accordionButton.dataset.close : accordionButton.dataset.open;
	} );
}


// SWIPER FOR HERO OF CARS WHEN NO VIVITION PLAYER AVAILABLE
const thumbsSwiper = await createSwiper('.carPicturesSwiper__thumbs', {
	spaceBetween: 6,
	slidesPerView: 7.2,
	freeMode: true,
	watchSlidesProgress: true,
	// speed: 580
	slidesPerView: 7.2,
})

const gallerySwiper = await createSwiper('.carPicturesSwiper__gallery', {
	spaceBetween: 0,
	navigation: {
		nextEl: '.carPicturesSwiper__gallery__next',
		prevEl: '.carPicturesSwiper__gallery__prev',
	},
	thumbs: {
		swiper: thumbsSwiper,
	},
	// speed: 580
	slidesPerView: 1.17,
	centeredSlides: true,
	loop: true,
	keyboard: {
		enabled: true,
		onlyInViewport: true,
	}
})

const heroImageHolder = document.querySelector(".hero-image-holder")
const carPicturesSwiperFullscreenButton = document.querySelector(".carPicturesSwiper__gallery__fullscreenButton");
carPicturesSwiperFullscreenButton.addEventListener("click", async () => {

	// WHEN FULLSCREEN OPENED
	if (heroImageHolder.classList.contains("fullscreen")) {

		// REMOVE FULLSCREEN
		heroImageHolder.classList.remove("fullscreen")

		// SET PARAMS
		thumbsSwiper.params.slidesPerView = 7.2;

	} 
	
	// WHEN FULLSCREEN CLOSED
	else {

		// ADD FULLSCREEN
		heroImageHolder.classList.add("fullscreen")

		// SET PARAMS
		thumbsSwiper.params.slidesPerView = 14;
	}

	// UPDATE SWIPERS
	gallerySwiper.update();
	thumbsSwiper.update();
});

  



